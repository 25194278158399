
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        title: {
            type: String
        },
        text: {
            type: [String, Number]
        },
        enable: {
            type: Boolean,
            default: false
        }
    }
});
